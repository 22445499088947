<template>
  <portal to="modal">
    <div class="modal-wrapper">
      <div class="overlay" @click="back()" />
      <div class="base-modal">
        <div class="modal-title" :class="colorclass">
          <span class="modal-title" :class="colorclass"> {{ title }}</span>
          <button class="back-button" :class="colorclass" @click="back()">x</button>
        </div>
        <div class="base-modal-content">
          <loading :active="isLoading" :isFullPage="false" />
          <!-- CBreadcrumbRouter / -->
          <transition name="fade" mode="out-in">
            <keep-alive>
              <component :is="component" v-bind="$attrs" />
            </keep-alive>
          </transition>
        </div>
      </div>
    </div>
  </portal>
</template>

<script>
import Loading from "vue-loading-overlay"
import "vue-loading-overlay/dist/vue-loading.css"

export default {
  name: "BaseModal",
  components: {
    Loading
  },
  props: {
    component: [Function],
    title: [String],
    colorclass: [String]
  },
  computed: {
    isLoading() {
      return this.$store.state.ui.modalLoading
    }
  },
  mounted() {
    document.addEventListener("keydown", this.checkEscape)
  },
  beforeDestroy() {
    document.removeEventListener("keydown", this.checkEscape)
  },
  methods: {
    checkEscape(e) {
      if (e.keyCode == 27) {
        this.$router.back()
      }
    },
    back() {
      if (this.$route.name === "selection-user-details") {
        this.$router.push({name: "maps"}, {...this.$route.query})
      } else if (this.$route.name === "map-user-details") {
        this.$router.push({name: "map"}, {...this.$route.query})
      }
    }
  }
}
</script>
<style scoped>
::v-deep.breadcrumb {
  padding: 0;
  justify-content: flex-end;
  margin-bottom: 0;
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.2s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
.modal-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
button.back-button {
  display: block;
  float: right;
  background: none;
  border: none;
  padding: 0px 5px;
  margin: 0;
  text-decoration: none;
  font-size: 16px;
  font-weight: 700;
  cursor: pointer;
  text-align: center;
  transition: background 250ms ease-in-out, transform 150ms ease;
  -webkit-appearance: none;
  -moz-appearance: none;
}

button.back-button.normal {
  color: hsla(45, 10%, 85%, 1);
}

button.back-button.emergency {
  color: hsla(50, 90%, 70%, 1);
}

button.back-button.normal:hover,
button.back-button.normal:focus {
  background: hsla(150, 55%, 60%, 1);
}

button.back-button.emergency:hover,
button.back-button.emergency:focus {
  background: hsla(15, 60%, 45%, 1);
}

button.back-button:focus {
  outline: 1px solid #fff;
  outline-offset: -4px;
}

button.back-button:active {
  transform: scale(0.99);
}

span.modal-title {
  font-size: 16px;
  font-weight: 700;
}

div.modal-title {
  padding: 0.5rem;
}

div.modal-title.normal {
  border-bottom: 1px solid hsla(150, 55%, 15%, 1);
  background-color: hsla(150, 55%, 30%, 1);
  color: hsla(45, 10%, 85%, 1);
}

div.modal-title.emergency {
  border-bottom: 1px solid hsla(300, 55%, 15%, 1);
  background-color: hsla(15, 60%, 55%, 1);
  color: hsla(50, 90%, 70%, 1);
}

.base-modal {
  position: absolute;
  z-index: 100;
  margin: auto;
  left: 5px;
  top: 10%;
  min-width: 600px;
  max-width: 800px;
  max-height: 100vh;
  border-radius: 5px;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: 0 3px 7px rgba(0, 0, 0, 0.3);
  -moz-box-shadow: 0 3px 7px rgba(0, 0, 0, 0.3);
  box-shadow: 0 3px 7px rgba(0, 0, 0, 0.3);
  -webkit-background-clip: padding-box;
  -moz-background-clip: padding-box;
  background-clip: padding-box;
}

.c-dark-theme .base-modal {
  background-color: var(--dark);
}

.base-modal-content {
  width: 100%;
  padding: 0.5rem;
}

.overlay {
  z-index: 1;
  position: absolute;
  width: 100vw;
  height: 100%;
}
</style>
